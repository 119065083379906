<template>
  <v-container style="overflow-y: auto; max-height: calc(100dvh - 226px);">
    <v-row
      v-for="(item, i) in items"
      :key="i">
      <v-col cols="2">
        <img
          :src="item.imageURL || noPicture"
          style="width: 100%; height: auto;"
          class="mx-auto my-2" />
      </v-col>
      <v-col cols="10">
        <div
          class="d-flex flex-row justify-space-between align-center"
          style="width: 100%;">
          <span style="font-size: 16px;">
            {{ item.name }}
          </span>
          <v-btn
            v-if="parent"
            color="secondary"
            :loading="loading"
            :disabled="disabled"
            @click="onSelect(item)">
            +
          </v-btn>
        </div>
        <v-simple-table>
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="`${i}-header-${header.value}`"
                :class="`text-${header.align || 'center'}`">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(sku, s) in item.skus"
              :key="`${i}-skus-${s}`">
              <td
                v-for="header in headers"
                :key="`${i}-header-${header.value}-skus-${s}`"
                :align="header.align || 'left'">
                <v-btn
                  v-if="header.value === 'actions'"
                  color="secondary"
                  :loading="loading"
                  :disabled="disabled"
                  @click="onSelect(sku)">
                  +
                </v-btn>
                <span
                  v-else
                  :style="header.style || ''">
                  {{ sku[header.value] || '-' }}
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="12">
        <hr>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NoPicture from '@/assets/image/no_picture_available.png'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    parent: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      noPicture: NoPicture
    }
  },
  computed: {
    headers () {
      const tmpHeaders = [
        {
          text: 'รหัส',
          value: 'code',
          align: 'left',
          style: ''
        },
        {
          text: 'สี',
          value: 'colorName',
          align: 'center',
          style: 'text-transform: uppercase;'
        },
        {
          text: 'ไซส์',
          value: 'sizeName',
          align: 'center',
          style: 'text-transform: uppercase;'
        }
      ]

      if (!this.parent) {
        tmpHeaders.push({
          text: '',
          value: 'actions',
          align: 'center',
          style: ''
        })
      }

      return tmpHeaders
    }
  },
  methods: {
    onSelect (item) {
      this.$emit('select', item)
    }
  }
}
</script>

<style scoped>

</style>
