import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class UnitOfMeasuresProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/unit-of-measures', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/unit-of-measures/${id}`)
  }

  createItem (form) {
    this.setHeader(getAuthToken())
    return this.post('/unit-of-measures', form)
  }

  updateItem (form) {
    this.setHeader(getAuthToken())
    return this.put(`/unit-of-measures/${form.id}`, form)
  }

  deleteItem (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/unit-of-measures/${id}`)
  }
}

export default UnitOfMeasuresProvider
