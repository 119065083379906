<template>
  <v-dialog
    v-model="dialog"
    width="80%"
    max-width="1200px">
    <v-card>
      <v-card-text class="pb-0">
        <v-container fluid>
          <v-row>
            <v-col :cols="single ? 12 : 8">
              <v-row
                justify="space-between"
                align="center"
                class="pt-3 mb-1">
                <v-col cols="auto">
                  <h2 v-if="type === 'FG'">
                    Finished Goods Items
                  </h2>
                  <h2 v-else-if="type === 'RM'">
                    Raw Materials
                  </h2>
                  <h2 v-else-if="type === 'BOM'">
                    Assembly BOM
                  </h2>
                </v-col>
                <v-col cols="auto">
                  <search-by-tags-and-brand
                    v-if="type === 'FG'"
                    v-model="query"
                    append-icon="mdi-magnify"
                    tags-width="300px"
                    :is-mobile="isMobile"
                    @on-search="onSearch()" />
                  <div
                    v-else
                    class="d-flex flex-row justify-end align-base-line">
                    <search-box
                      v-model="query.search"
                      :disabled="loading"
                      @on-search="onSearch()" />
                  </div>
                </v-col>
              </v-row>
              <product-table
                v-if="type === 'FG'"
                :items="items"
                :limit="query.limit"
                :parent="parent"
                :loading="loading"
                :disabled="disabled"
                @select="onSelect($event)" />
              <purchase-item-table
                v-else-if="type === 'RM'"
                :items="items"
                :limit="query.limit"
                :loading="loading"
                :disabled="disabled"
                @select="onSelect($event)" />
              <assembly-bom-table
                v-else-if="type === 'BOM'"
                :items="items"
                :limit="query.limit"
                :loading="loading"
                :disabled="disabled"
                @select="onSelect($event)" />
              <v-pagination
                v-model="query.page"
                color="secondary"
                :total-visible="7"
                :length="pageCount"
                :disabled="loading || disabled" />
            </v-col>
            <v-col
              v-if="!single"
              cols="4"
              style="border-left: 1px solid black;"
              class="pt-6">
              <v-row style="overflow-y: auto; max-height: calc(100dvh - 68px);">
                <v-col
                  v-for="(item, i) in selected"
                  :key="`${i}-selected`"
                  cols="12">
                  <v-row>
                    <v-col cols="3">
                      <img
                        :src="item.imageURL || noPicture"
                        style="max-height: 80px;"
                        class="mx-auto" />
                    </v-col>
                    <v-col
                      cols="9"
                      class="d-flex flex-column"
                      style="position: relative;">
                      <span>
                        {{ item.itemNo || item.lineItemNo }}
                      </span>
                      <span>
                        {{ type === 'RM' ? `Name: ${item.description || item.lineItemNo || '-'}` : `Color: ${item.color || '-'}` }}
                      </span>
                      <span>
                        {{ type === 'RM' ? `Description: ${item.description2 || item.lineDescription || '-'}` : `Size: ${item.size || '-'}` }}
                      </span>
                      <gw-icon-hover
                        small
                        style="position: absolute; z-index: 30; top: 8px; right: 8px;"
                        icon-name="mdi-delete"
                        icon-hover="mdi-delete-empty"
                        color="error"
                        :disabled="loading || disabled"
                        @click="onRemove(item)" />
                    </v-col>
                    <v-col cols="12">
                      <hr>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import PurchaseItemsProvider from '@/resources/PurchaseItemsProvider'
import ProductProvider from '@/resources/ProductProvider'
import AssemblyBOMProvider from '@/resources/AssemblyBOMProvider'
import NoPicture from '@/assets/image/no_picture_available.png'
import SearchBox from '@/components/SearchBox.vue'
import SearchByTagsAndBrand from '@/components/SearchByTagsAndBrand.vue'
import PurchaseItemTable from './PurchaseItemTable.vue'
import ProductTable from './ProductTable.vue'
import AssemblyBomTable from './AssemblyBOMTable.vue'

const PurchaseItemsService = new PurchaseItemsProvider()
const ProductService = new ProductProvider()
const AssemblyBOMService = new AssemblyBOMProvider()

export default {
  components: {
    SearchBox,
    PurchaseItemTable,
    ProductTable,
    AssemblyBomTable,
    SearchByTagsAndBrand
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'RM'
    },
    parent: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      items: [],
      pageCount: 0,
      query: {
        search: '',
        page: 1,
        limit: 10
      },
      noPicture: NoPicture,
      purchaseItemTypes: [
        {
          text: 'ผ้า',
          value: 'fabric'
        },
        {
          text: 'อะไหล่',
          value: 'material'
        },
        {
          text: 'อุปกรณ์แพ็ค',
          value: 'packaging'
        },
        {
          text: 'อุปกรณ์เสริม',
          value: 'supply'
        },
        {
          text: 'ค่าแรงตัดเย็บ',
          value: 'labour'
        },
        {
          text: 'ค่าขนส่ง',
          value: 'freight'
        }
      ]
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    selected () {
      return this.selectedItems
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    }
  },
  watch: {
    type (val) {
      if (val === 'FG') {
        this.query = {
          page: 1,
          limit: 10,
          sortBy: 'createdAt',
          sortOrder: 'desc',
          search: '',
          tags: [],
          tagOperation: 'OR',
          brand: 'all'
        }

        this.getProducts(true)
      } else if (this.type === 'RM') {
        this.getPurchaseItems(true)
      } else if (this.type === 'BOM') {
        this.getAssemblyBOMItems(true)
      }
    },
    'query.page': {
      handler () {
        if (this.type === 'FG') {
          this.getProducts()
        } else if (this.type === 'RM') {
          this.getPurchaseItems()
        } else if (this.type === 'BOM') {
          this.getAssemblyBOMItems()
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.type === 'FG') {
      this.query = {
        page: 1,
        limit: 10,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all'
      }

      this.getProducts(true)
    } else if (this.type === 'RM') {
      this.getPurchaseItems(true)
    } else if (this.type === 'BOM') {
      this.getAssemblyBOMItems(true)
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    async getAssemblyBOMItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.items = []
          this.query.page = 1
        }

        const { data } = await AssemblyBOMService.getItems({
          search: this.query.search,
          page: this.query.page,
          limit: this.query.limit
        })

        // this.items = data.results.map((re) => ({ ...re, itemType: 'BOM' }))
        this.items = data.results
        this.pageCount = data.pages
      } catch (error) {
        console.error('getAssemblyBOMItems', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getPurchaseItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.items = []
          this.query.page = 1
        }

        const { data } = await PurchaseItemsService.getItems({
          search: this.query.search,
          page: this.query.page,
          limit: this.query.limit
        })

        this.items = data.results.map((re) => ({ ...re, itemType: 'FG' }))
        this.pageCount = data.pages
      } catch (error) {
        console.error('getPurchaseItems', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getProducts (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.items = []
          this.query.page = 1
        }

        const { data } = await ProductService.getProducts(this.query)

        this.items = data.results.map((item) => ({
          ...item,
          imageURL: item.photoUrls[0],
          gwCollection: item.gwCollection?.nickname || '',
          itemType: 'FG',
          skus: item.skus.map((sku) => ({
              ...sku,
              name: item.name,
              model: item.model,
              colorName: sku.color.name,
              sizeName: sku.size.name,
              imageURL: item.photoUrls[0],
              groupCategories: item.groupCategories,
              categories: item.categories,
              gwCollection: item.gwCollection?.nickname || '',
              brand: item.brand,
              itemType: 'FG'
            }))
        }))
        this.pageCount = data.pages
      } catch (error) {
        console.error('getProducts', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    onSearch () {
      if (this.type === 'FG') {
        this.getProducts(true)
      } else if (this.type === 'RM') {
        this.getPurchaseItems(true)
      } else if (this.type === 'BOM') {
        this.getAssemblyBOMItems(true)
      }
    },
    onSelect (item) {
      if (this.selected.some((s) => s.itemNo === item.itemNo) || this.selected.some((s) => s.itemNo === item.code)) {
        return
      }

      if (this.parent && this.selected.some((s) => s.itemNo === item.model)) {
        return
      }

      this.$emit('select', item)
    },
    onRemove (item) {
      this.$emit('remove', item)
    },
    closeForm () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
