import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PurchaseItemsProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('purchase-items', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`purchase-items/${id}`)
  }

  getStockMovement (id, query) {
    this.setHeader(getAuthToken())
    return this.get(`purchase-items/${id}/stock-movement`, query)
  }

  getStockReserve (id, query) {
    this.setHeader(getAuthToken())
    return this.get(`purchase-items/${id}/stock-reserve`, query)
  }

  createItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('purchase-items', payload)
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.put(`purchase-items/${payload.id}`, payload)
  }

  deleteItem (id) {
    this.setHeader(getAuthToken())
    return this.delete(`purchase-items/${id}`)
  }

  syncAll () {
    this.setHeader(getAuthToken())
    return this.post('purchase-items/migrate-bc')
  }
}

export default PurchaseItemsProvider
