var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.limit,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.imageURL",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mx-auto my-2",staticStyle:{"max-height":"80px"},attrs:{"src":item.imageURL || _vm.noPicture}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description || '-')+" ")]}},{key:"item.description2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description2 || '-')+" ")]}},{key:"item.purchaseItemType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mapPurchaseType(item.purchaseItemType))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","loading":_vm.loading},on:{"click":function($event){return _vm.onSelect(item)}}},[_vm._v(" + ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }