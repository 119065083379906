<template>
  <v-data-table
    class="mt-5"
    :headers="headers"
    :items="items"
    :items-per-page="limit"
    :loading="loading"
    hide-default-footer>
    <template #[`item.imageURL`]="{ item }">
      <img
        :src="item.imageURL || noPicture"
        style="max-height: 80px;"
        class="mx-auto my-2" />
    </template>
    <template #[`item.description`]="{ item }">
      {{ item.description || '-' }}
    </template>
    <template #[`item.description2`]="{ item }">
      {{ item.description2 || '-' }}
    </template>
    <template #[`item.purchaseItemType`]="{ item }">
      {{ mapPurchaseType(item.purchaseItemType) }}
    </template>
    <!-- <template #[`item.lotControl`]="{ item }">
      <v-icon
        v-if="item.lotControl"
        color="success">
        mdi-check
      </v-icon>
      <v-icon
        v-else
        color="error">
        mdi-close
      </v-icon>
    </template>
    <template #[`item.blocked`]="{ item }">
      <v-icon
        v-if="item.blocked"
        color="success">
        mdi-check
      </v-icon>
      <v-icon
        v-else
        color="error">
        mdi-close
      </v-icon>
    </template> -->
    <template #[`item.actions`]="{ item }">
      <v-btn
        color="secondary"
        :loading="loading"
        @click="onSelect(item)">
        +
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import NoPicture from '@/assets/image/no_picture_available.png'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 10
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      noPicture: NoPicture,
      headers: [
        {
          text: 'รูปภาพ',
          value: 'imageURL',
          sortable: false
        },
        {
          text: 'รหัส',
          value: 'itemNo',
          sortable: false
        },
        {
          text: 'คำอธิบาย',
          value: 'description',
          sortable: false
        },
        // {
        //   text: 'คำอธิบาย 2',
        //   value: 'description2',
        //   sortable: false
        // },
        {
          text: 'ประเภท',
          value: 'type',
          sortable: false
        },
        {
          text: 'ประเภทวัตถุดิบ',
          value: 'purchaseItemType',
          sortable: false
        },
        // {
        //   text: 'สินค้าคุม LOT',
        //   value: 'lotControl',
        //   align: 'center',
        //   sortable: false
        // },
        // {
        //   text: 'ยกเลิกรายการ',
        //   value: 'blocked',
        //   align: 'center',
        //   sortable: false
        // },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],
      purchaseItemTypes: [
        {
          text: 'ผ้า',
          value: 'fabric'
        },
        {
          text: 'อะไหล่',
          value: 'material'
        },
        {
          text: 'อุปกรณ์แพ็ค',
          value: 'packaging'
        },
        {
          text: 'อุปกรณ์เสริม',
          value: 'supply'
        },
        {
          text: 'ค่าแรงตัดเย็บ',
          value: 'labour'
        },
        {
          text: 'ค่าขนส่ง',
          value: 'freight'
        }
      ]
    }
  },
  methods: {
    mapPurchaseType (type) {
      const foundType = this.purchaseItemTypes.find((vt) => vt.value === type)

      return foundType?.text || type
    },
    onSelect (item) {
      this.$emit('select', item)
    }
  }
}
</script>

<style scoped>

</style>
