<template>
  <v-data-table
    class="mt-5"
    :headers="headers"
    :items="items"
    :items-per-page="limit"
    :loading="loading"
    hide-default-footer>
    <template #[`item.imageURL`]="{ item }">
      <img
        :src="item.imageURL || noPicture"
        style="max-height: 80px;"
        class="my-2" />
    </template>
    <template #[`item.description`]="{ item }">
      {{ item.description || '-' }}
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
        color="secondary"
        outlined
        :loading="loading"
        @click="onSelect(item)">
        Select
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import NoPicture from '@/assets/image/no_picture_available.png'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 10
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      noPicture: NoPicture,
      headers: [
        {
          text: 'รูปภาพ',
          value: 'imageURL',
          sortable: false
        },
        {
          text: 'รหัส',
          value: 'parentItemNo',
          sortable: false
        },
        {
          text: 'ชื่อสินค้า',
          value: 'description',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  methods: {
    onSelect (item) {
      this.$emit('select', item)
    }
  }
}
</script>

<style scoped>

</style>
